.soMe{
  margin-top: 1em;
  display: inline-flex;
  flex-direction: row;
}
.spinner {
  padding-top: .6em;
}
.ok {
  padding-left: 0.6em;
  padding-top: 0.1em;
}
.ok img {
  max-width: 1.3em;
}
.soMe input {
  width: 7em;
  height: 1em;
}

.soMe label {
  font-size: 0.95em;
  font-weight: 600;
  line-height: 1.9em;
  min-width: 9.5em;
  margin-top: 0;
}
.toast {
  background: linear-gradient(5deg,#f50d6a,#f35b36)!important;
  border-radius: 0.4em!important;
}
.link {
  padding-left: 10.5em;
  font-size: 1rem;
}
