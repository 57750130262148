.Main {
  flex: 1 0 auto;
  padding: 0 0.5em;
}

.logo, .colorlogo{
  max-height: 2em;
}
.bottomLogo {
  padding-top: 0.3em;
  max-height: 1.2em;
}
.colorlogo {
  display: none;
}

nav {
  margin-left: auto;
  display: inline-block;
  vertical-align: right;
  text-align: right;
}
nav ul {
  margin: 0;
  padding-inline-start: 1em;
}

nav ul li {
  display: inline-block;
  padding: 0 0.5em;
  list-style-type: none;
}

header {
  display: none;
  flex-direction: row;
  justify-content: space-between;
  position: sticky;
  position: -webkit-sticky;
  border-bottom: 1px solid #f50d6a;
  top: 0;
  padding: 0.75em;
  background-color: #fff;
  z-index: 100;
}

.menu {
  width: 15em;
  position: fixed;
  background: transparent;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.menuBg {
  width: 15em;
  padding: 1em;
  background-color: #555;
  height: 100%;
  overflow-y: auto;
  color: #fff;
}
.topIcon{
  background: linear-gradient(5deg,#f50d6a,#f35b36);
  margin: -1em -1em 1em -1em;
  padding: 1em 0em 0.5em 1em;
}

.outsideMenu {
  height: 100%;
  width: 0;
}

.menu a {
  color: #fff;
}
.menu .passive {
  color: #888;
}
.menu a:hover {
  color: #ccc;
}
.menu ul {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin: 0;
  padding-inline-start: 0;
  padding-left: 0;
}

.menu ul li {
  padding: 0 0.5em;
  list-style-type: none;
  padding: 0 0.5em;
  list-style-type: none;
}

.menuBg > ul > li {
  padding: 0;
}

.position {
  font-size: 110%;
  font-weight: 700;
}

.hamburger {
  padding-top: 0.4em;
  width: 20px;
  cursor: pointer;
}
.hamburger.cross {
  padding: 0 0 0 0.5em;
}

.hamburger:after,
.hamburger:before,
.hamburger div {
  background-color: #333;
  border-radius: 3px;
  content: "";
  display: block;
  height: 3px;
  margin: 3px 0;
  transition: all 0.35s ease-in-out;
}
.menu .hamburger:after,
.menu .hamburger:before,
.menu .hamburger div {
  background-color: #fff;
  display: none;
}

.menuOpen .hamburger:before {
  transform: translateY(12px) rotate(135deg);
}

.menuOpen .hamburger:after {
  transform: translateY(0px) rotate(-135deg);
}

.menuOpen .hamburger div {
  transform: scale(0);
}

@media only screen and (max-width: 1023px) {
  .menu {
    width: 100vw;
    position: fixed;
    top: -1em;
    left: -100vw;
    height: 100%;
    margin: 1em 0;
    z-index: 110;
    transition: all 0.35s ease-in-out;
  }
  .menu .hamburger {
    float: right;
  }
  .menu .hamburger:after,
  .menu .hamburger:before,
  .menu .hamburger div {
    display: block;
  }
  .menuOpen {
    left: 0;
  }
  .outsideMenu {
    height: 100%;
    width: calc(100vw - 15em);
  }
  .colorlogo {
    display: block;
  }
  header {
    display: flex;
  }
}