.login {
  background-color: #fff;
  padding: 2em;
  margin: 2em auto;
  max-width: 800px;
}

.login label {
  font-size: 0.9em;
}

.login button {
  margin-top: 2em;
  margin-bottom: 2em;
  list-style-type: none;
}

.error {
  color: #f50d6a;
  font-weight: 700;
}