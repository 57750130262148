.head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.head h1 {
  margin-block-end: 0;
}
.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.topLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.topLeft > div {
  padding-right: 2em;
  margin-bottom: 2em;
}
.dev, .book {
  width: 21em;
  max-width: calc(100vw - 20px);
}

.image {
  line-height: 0;
}
.image img{
  border: 1px solid #ddd;
}

.passiveProfile {
  font-size: 1.3em;
  font-weight: 700;
  z-index: 100;
  color: #f50d6a;
  width: 100%;
}

.active {
  height: 3em;
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media only screen and (max-width: 500px) { 
  .active {
    padding-top: 1em;
  }
}
.active label {
  font-size: 0.95em;
  font-weight: 600;
  line-height: 1.9em;
  margin-top: 0;
}

.checkBox {
  margin-left: 1em;
}
.book {
  padding-bottom: 0.5em;
}
.ok {
  padding-left: 0.6em;
  padding-top: 0.1em;
}
.ok img {
  max-width: 1.3em;
}
.info {
  min-width: 300px;
}

@media only screen and (max-width: 600px) { 
  .info {
    width: 100%;
    min-width: 200px;
  }
}
.skillSection {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.skillSection img {
  height: 1.8em;
  padding-right: 1em;
}

.skills {
  padding-inline-start: 0;
  padding-left: 0;
  margin-block-start: 0;
  padding-bottom: 0;
}

ul.skills {
  max-width: calc(100% - 3em);
}

.skills li {
  display: inline-block;
  padding: 0;
  margin: 0 0.2em 0 0;
  list-style-type: none;

}
.skills li:after{
  content: ",";
}
.skills li:last-child::after{
  content: "";
}

.test {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.test > div {
  margin-right: 1em;
}

.testReport, .testUrl {
  color: #f50d6a;
  font-size: 1rem;
  font-weight: 300;
  padding: 0.1em;
  background: none!important;
  border: none;
  cursor: pointer;
}
.testUrl {
  font-weight: 400;
}
.testReport:focus, .testUrl:focus {
  outline: none;
}
.testReport:hover, .testUrl:hover {
  color: #f35b36;
}
.test img {
  height: 1.8em;
  padding-right: 1em;
}
.testScore {
  font-weight: 600;
}
.updateCV {
  font-size: 0.9em;
}
.iframe {
  padding: 0 1em 0 0;
  border: none;
  width: calc(100% - 0.5em);
  height: 80vh;
}

a {
  color: #f50d6a;
}
.spinner {
  padding-top: .5em;
}
