.countDown {
  margin-bottom: 1.5em;
}
.clockdiv{
	color: #f50d6a;
  display: block;
  margin-left: -5px;
	font-weight: 400;
	font-size: 1.2em;
}

.clockdiv > div{
  padding: 0 5px 5px 5px;
  text-align: center;
  margin-right: 3px;
	border-radius: 3px;
	display: inline-block;
}

.clockdiv div > span{
  padding: 0 1px;
  min-width: 30px;
	border-radius: 3px;
	display: inline-block;
}

.smalltext{
  font-size: 14px;
  line-height: 1em;
  text-align: left;
}
.schedule {
  font-size: 14px;
	color: #f50d6a;
	font-weight: 400;
  line-height: 1em;
}
.booking {
  max-width: 300px;
	color: #f50d6a;
  font-size: 14px;
  line-height: 1.4em;
}

.booking p {
  padding: 0 0 0.5em 0;
}

.booking textarea {
  height: 4.5em;
  width: 16em;
  margin-bottom: 0.2em;
}

.bookingTimedOut {
  max-width: 300px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6em;
}

.toast {
  line-height: 1.3em;
  background: linear-gradient(5deg,#f50d6a,#f35b36)!important;
  border-radius: 0.4em!important;

}
.toast > div {
  padding: 0.4em;
}

input.bookButton {
  margin-bottom: 1em;
  font-size: 18px;
  width: 12em;
}