body {
  margin: 0;
  font-family:  -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  line-height: 1.9em;
  font-weight: 300;
  background-color: #f4f4f4;
  color: #333;
}
@media only screen and (max-width: 1023px) {
  body {
    background-color: #fff;
  }
}

html, body {
  height: 100%
}
#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin: 0;
  padding: 0.5em 0;
  line-height: 1.6em;
}
h1 {
  font-weight: 600;
  line-height: 1em;
  font-size: 56px;
  margin-top: 0;
  margin-block-end: 0.2em;
}

h2 {
  font-weight: 500;
  font-size: 31px;
  margin-block-end: 0.5em;
  margin-block-start: 0.5em;

}

h3 {
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 10px;
  margin-block-end: 0.5em;
  margin-block-start: 0.5em;
}
hr {
  margin: 1.5em -2em;
  border: none;
  border-top: 8px solid #f4f4f4;
}

@media only screen and (max-width: 1023px) {
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 26px;
  }
  h3 {
    font-size: 20px;
  }
  hr {
    margin: 1em 0;
    border-top: 1px solid #ddd;
  }
}

a {
  text-decoration: none;
  color: #eee;
}
a:hover{
  color: #666;
}

footer {
  display: none;
  padding: 0.5em 0;
  flex-shrink: 0;
  background: linear-gradient(5deg,#f50d6a,#f35b36);
  color: #fff;
}
footer a {
  padding-top: 0.25em;
}


.container {
  background-color: #fff;
  margin: 1em 1em 1em 16em;
  padding: 1.5em;
  max-width: 1200px;
}
@media only screen and (max-width: 1023px) {
  footer {
    display: block;
  }
  .container {
    margin: 0;
    padding: 1.5em 0;
  }
}

footer > div {
  padding-left: 0.5em;
  padding-right: 0.5em;
  margin: 0 0 0 18em;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media only screen and (max-width: 1023px) {
  footer > div {
    margin: 0;
  }
}

label, legend {
  display: block;
  font-size: 1.6rem;
  font-weight: 700;
  margin-top: 1em;
  line-height: 1.6em;
}
input[type='email'], 
input[type='number'], 
input[type='password'], 
input[type='search'], 
input[type='tel'], 
input[type='text'], 
input[type='url'], 
textarea, select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 0.1rem solid #f50d6a22;
  border-radius: .4rem;
  box-shadow: none;
  box-sizing: inherit;
  height: 1.2em;
  padding: .6rem 1.0rem;
  width: 30em;
  max-width: 65vw;
  font-size: 16px;
}
input[type='email']:focus, 
input[type='number']:focus, 
input[type='password']:focus, 
input[type='search']:focus, 
input[type='tel']:focus, 
input[type='text']:focus, 
input[type='url']:focus, textarea:focus, select:focus {
  border-color: #f50d6a;
  outline: 0;
}

button {
  cursor: pointer;
}

button, input[type='button'] {
  padding: 0.8em 3em;
  background: linear-gradient(5deg,#f50d6a,#f35b36);
  border-radius: 3em;  
  border: 1px solid #d1d1d1;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
}
button:hover, input[type='button']:hover {
  background: linear-gradient(5deg,#f50d6aaa,#f35b36aa);
}
button:focus, input[type='button']:focus {
  outline: none;
}

b {
  font-weight: 600;
  font-size: 0.95em;
}

.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip::before {
  content: "ⓘ";
  vertical-align: super;
  padding: 0.2em;
  margin-top: -2.4em;
  font-size: 0.7rem;
}

/* Tooltip text */
.tooltip .tooltiptext {
  display: none;
  width: 20rem;
  overflow: hidden;
  font-size: 0.9rem;
  line-height: 1.5rem;
  max-width: calc(100vw - 150px);
  top: 100%;
  left: 50%;
  margin-left: -50px; /* Use half of the width (120/2 = 60), to center the tooltip */
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}
/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  display: inline;
}