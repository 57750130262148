.change {
  margin-top: 1em;
  margin-bottom: 1em;
  max-width: 250px;
  font-size: 14px;
  line-height: 1.4em;
}

.change p {
  padding: 0 0 0.5em 0;
}

textarea {
  height: 4.5em;
  width: calc(100% - 2em);
  margin-bottom: 0.2em;
}

.toast {
  line-height: 1.3em;
  background: linear-gradient(5deg,#f50d6a,#f35b36)!important;
  border-radius: 0.4em!important;
}
.toast > div {
  padding: 0.4em;
}

.changeButton {
  font-size: 1rem;
  margin-top: 1.5em;
}

.report {
  margin-right: 1em;
}