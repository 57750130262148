.availability{
  display: inline-flex;
  flex-direction: row;
}
.changeAvailability {
  width: 7em;
}
.spinner {
  padding-top: .6em;
}
.ok {
  padding-left: 0.6em;
  padding-top: 0.1em;
}
.ok img {
  max-width: 1.3em;
}
.availability label {
  font-size: 0.95em;
  font-weight: 600;
  line-height: 1.9em;
  min-width: 9.5em;
  margin-top: 0;
}
